import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route } from 'react-router-dom';
import ActualsView from './actuals/views/Actuals';
import GlobalView from './global/views/Global';
import RecommendationsView from './recommendations/views/Recommendations';
import PrivateRoute from './shared/components/PrivateRoute/PrivateRoute';
import RouterSwitch from './shared/components/RouterSwitch/RouterSwitch';
import useChannelStore from './shared/data/channels';
import NotFoundView from './shared/views/NotFound';
import StrategyView from './strategy/views/Strategy';
import SupportView from './support/views/Support';
import UploadsView from './uploads/views/Uploads';
import InventoryAllocationView from './vulcan/inventoryAllocation/views/InventoryAllocationView';
import InventoryAllocationsView from './vulcan/inventoryAllocations/views/InventoryAllocations';

const EnabledRoutes = () => {
	const { activeChannel } = useChannelStore();
	const isEnabledCheck = (isEnabled: boolean | [string]): boolean => {
		if (typeof isEnabled === 'boolean') {
			return isEnabled;
		}
		return isEnabled?.includes(activeChannel) ?? false;
	};

	const [enabledRoutes, setEnabledRoutes] = useState<{ [id: string]: boolean }>(
		{
			'/actuals': isEnabledCheck(window._ENV_.REACT_APP_ACTUALS_ENABLED),
			'/strategy': isEnabledCheck(window._ENV_.REACT_APP_STRATEGY_ENABLED),
			'/recommendations': isEnabledCheck(
				window._ENV_.REACT_APP_RECOMMENDATIONS_ENABLED
			),
			'/uploads': isEnabledCheck(
				JSON.parse(
					window._ENV_.REACT_APP_FILE_UPLOADER_ENABLED.replaceAll("'", '')
				)
			),
			'/support': isEnabledCheck(window._ENV_.REACT_APP_SUPPORT_ENABLED),
			'/global': isEnabledCheck(window._ENV_.REACT_APP_GLOBAL_CHANNEL_ENABLED),
		}
	);

	useEffect(() => {
		setEnabledRoutes({
			'/actuals': isEnabledCheck(window._ENV_.REACT_APP_ACTUALS_ENABLED),
			'/strategy': isEnabledCheck(window._ENV_.REACT_APP_STRATEGY_ENABLED),
			'/recommendations': isEnabledCheck(
				window._ENV_.REACT_APP_RECOMMENDATIONS_ENABLED
			),
			'/uploads': isEnabledCheck(
				JSON.parse(
					window._ENV_.REACT_APP_FILE_UPLOADER_ENABLED.replaceAll("'", '')
				)
			),
			'/support': isEnabledCheck(window._ENV_.REACT_APP_SUPPORT_ENABLED),
			'/global': isEnabledCheck(window._ENV_.REACT_APP_GLOBAL_CHANNEL_ENABLED),
		});
	}, [activeChannel]);

	return (
		<RouterSwitch>
			<Redirect
				exact
				from="/"
				to={
					window._ENV_.REACT_APP_VULCAN_ENABLED
						? '/stock'
						: window._ENV_.REACT_APP_DEFAULT_PATH
				}
			/>
			<Redirect exact from="/stock" to="/stock/inventory-allocations" />
			{window._ENV_.REACT_APP_VULCAN_ENABLED && (
				<PrivateRoute path="/stock/inventory-allocations/:id">
					<Helmet title="Crunch Platform | Vulcan" />
					<InventoryAllocationView />
				</PrivateRoute>
			)}
			{window._ENV_.REACT_APP_VULCAN_ENABLED && (
				<PrivateRoute path="/stock/inventory-allocations">
					<Helmet title="Crunch Platform | Vulcan" />
					<InventoryAllocationsView />
				</PrivateRoute>
			)}
			{enabledRoutes['/actuals'] && (
				<PrivateRoute path="/actuals">
					<Helmet title="Markmi | Actuals" />
					<ActualsView />
				</PrivateRoute>
			)}
			{enabledRoutes['/strategy'] && (
				<PrivateRoute path="/strategy">
					<Helmet title="Markmi | Strategy" />
					<StrategyView />
				</PrivateRoute>
			)}
			<Redirect from="/opportunities" to="/recommendations" />
			<Redirect from="/scenario" to="/recommendations" />
			{enabledRoutes['/recommendations'] && (
				<PrivateRoute path="/recommendations">
					<Helmet title="Markmi | Recommendations" />
					<RecommendationsView />
				</PrivateRoute>
			)}
			{enabledRoutes['/support'] && (
				<PrivateRoute path="/support">
					<Helmet title="Markmi | Support" />
					<SupportView />
				</PrivateRoute>
			)}
			{enabledRoutes['/uploads'] && (
				<PrivateRoute path="/uploads">
					<Helmet title="Markmi | Uploads" />
					<UploadsView />
				</PrivateRoute>
			)}
			{enabledRoutes['/global'] && (
				<PrivateRoute path="/global">
					<Helmet title="Markmi | Global" />
					<GlobalView />
				</PrivateRoute>
			)}
			<Route path="/" exact>
				<Redirect to={window._ENV_.REACT_APP_DEFAULT_PATH} />
			</Route>
			<Route path="/page-not-found">
				<Helmet title="Markmi | Page not found" />
				<NotFoundView />
			</Route>
		</RouterSwitch>
	);
};
export default EnabledRoutes;
